import './index.scss'
import React, {useContext} from 'react'
import {useNavigate} from "react-router-dom";
import ConfirmButton from "../../component/ConfirmButton";
import successIcon from "../../imgs/success.png"
import {Image} from "antd-mobile";
import {Trans} from "react-i18next";
import {AppDataContext} from "../../providers/AppDataProvider";

const UnfinishSuccess = () => {
    const navigate = useNavigate()
    const [appData,setAppData] = useContext(AppDataContext)
    return (
        <div className={'finish-success-box'}>
            <Image className={'image'} src={successIcon}/>
            <div className={'title'}>
                <span><Trans>af_awfinishpage.title</Trans></span>
            </div>
            <div className={'desc'}>
                <Trans>af_awfinishpage.body</Trans>
            </div>
            <ConfirmButton className={"confirm-button"}  title={<Trans>af_awfinishpage.button</Trans>} action={() => {
                const _appData = {...appData}
                _appData.order_id = ''
                setAppData(_appData)

                navigate('/')
            }}/>
        </div>

    )
}

export default UnfinishSuccess
