import './index.scss'
import React from 'react'
import {Divider} from "antd-mobile";

const HomeGuide = (props) => {
    return (
        <div className="home-guide">
            <div className={"home-guide-title"}>{props.title}</div>
            <div className={"home-guide-desc"}>{props.desc}
            </div>
            <Divider className={'divider'}/>
        </div>

    )
}

export default HomeGuide
