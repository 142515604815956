import './index.scss'
import React, {useContext, useState} from 'react'
import HomeGuide from "../../component/HomeGuide";
import {Image} from "antd-mobile";
import ConfirmButton from "../../component/ConfirmButton";
import {useNavigate} from "react-router-dom";
import {Trans} from "react-i18next";
import {AppDataContext} from "../../providers/AppDataProvider";
import {postRequest} from "../../utils/axios";
import {useQuery} from "@apollo/client";
import {QUERY_PRUDUCT} from "./graphql";

const ActivateWarrantyStep1 = () => {

    const navigate = useNavigate()
    const [username, setUserName] = useState()
    const [email, setEmail] = useState()
    const [product, setProduct] = useState()
    const [emailError, setEmailError] = useState()
    const [usernameError, setUserNameError] = useState()
    const [appData, setAppData] = useContext(AppDataContext)
    if (!appData.config) {
        navigate('/')
    }

    useQuery(QUERY_PRUDUCT,{
        variables: {asin: appData.asin},
        onCompleted: (data) => {
            setProduct(data['product'][0]['data'])
        }
    })

    const currencyCode = {
        'EUR': '€',
        'AUD': '$',
        'JPY': '¥',
        'USD': '$',
        'CAD': '$',
        'GBP': '£',
    }


    return (
        <div className={'activate-1-box'}>
            <HomeGuide title={<Trans>af_homepage.awcard.title</Trans>}
                       desc={<Trans>af_homepage.awcard.body</Trans>}/>
            <div className={'goods-info'}>
                <div className={'goods-info-image-box'}>
                    <Image fit={"scale-down"} src={appData.icon_url}
                           className={"goods-info-image-box-img"}/>
                </div>
                <div className={"goods-info-msg"}>
                    <div>ASIN: <span>{appData.asin}</span></div>
                    <div>Price: <span>{product ? currencyCode[product['currency_code']]:''}{appData.price}{product?product['currency_code']:''}</span></div>
                    {/*<div>Sold by xxx</div>*/}
                </div>
            </div>

            <div className={'info-input'}>
                <div className={'info-input-box'}>
                    <label><Trans>af_awuserinfopage.inputname_hint</Trans></label>
                    <input type="text" placeholder={'Username'} onChange={(res) => setUserName(res.target.value)}/>
                    {usernameError && <span className={'input-tips'}>{usernameError}</span>}
                </div>
                <div className={'info-input-box'}>
                    <label><Trans>af_awuserinfopage.inputemail_hint</Trans></label>
                    <input type="email" placeholder={'Email'} onChange={(res) => setEmail(res.target.value)}/>
                    {emailError && <span className={'input-tips'}>{emailError}</span>}
                </div>
            </div>
            <ConfirmButton  className={"confirm-button"} title={<Trans>af_awuserinfopage.button</Trans>}
                           action={() => {
                               console.log(email)
                               if (!username ) {
                                   setUserNameError('please input your username')
                               } else {
                                   setUserNameError('')
                               }
                                if (!email ) {
                                   setEmailError('please input your email')

                               }else {
                                    setEmailError('')
                                }
                                if (!email || !username) {
                                    return;
                                }
                               if (!(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email))) {
                                    setEmailError('Email format error')
                                   return
                               }
                               postRequest('/userapi/upsert_act_user?act_id=' + appData.act_id, {
                                   email: email,
                                   username: username
                               }).then(res => {
                                   const _appData = {...appData}
                                   _appData.user_id = res.user.id
                                   _appData.email = email
                                   _appData.username = username
                                   _appData.review_id = res.act.id
                                   _appData.gift_order_id = res.act.gift_order_id
                                   setAppData(_appData)
                                   navigate('/activate-warranty-step-2')
                               })
                               // navigate('/activate-warranty-step-2')
                           }}/>
        </div>

    )
}

export default ActivateWarrantyStep1
