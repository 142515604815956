import './index.scss'
import React, {useContext, useState} from 'react'
import HomeGuide from "../../component/HomeGuide";
import {Image, TextArea, Toast} from "antd-mobile";
import ConfirmButton from "../../component/ConfirmButton";
import {useNavigate} from "react-router-dom";
import editIcon from "../../imgs/edit.png";
import starIcon from "../../imgs/star.png";
import starSelectIcon from "../../imgs/start_select.png";
import {Trans} from "react-i18next";
import {AppDataContext} from "../../providers/AppDataProvider";
import {postRequest} from "../../utils/axios";

const ActivateWarrantyStep2 = () => {
    const navigate = useNavigate()
    const [appData, setAppData] = useContext(AppDataContext)
    if (!appData.config) {
        navigate('/')
    }
    const [rateValue, setRateValue] = useState(0);
    const [email, setEmail] = useState(appData.email);
    const [comment, setComment] = useState('');
    const [commentError, setCommentError] = useState();
    const [username, setUsername] = useState(appData.username);

    return (
        <div className={'box'}>
            <HomeGuide title={<Trans>af_awreviewpage.title</Trans>}
                       desc={<Trans>af_awreviewpage.body</Trans>}/>
            <div className={'user-info'}>
                <div className={'user-info-item'}><label><Trans>af_awreviewpage.input_title.0</Trans></label><input
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    defaultValue={appData.username}/><Image
                    src={editIcon}/></div>
                <div className={'user-info-item'}><label><Trans>af_awreviewpage.input_title.1</Trans></label><input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={appData.email}/><Image
                    src={editIcon}/></div>
                <div className={'user-info-rate'}>
                    <label className={'user-info-rate-title'}><Trans>af_awreviewpage.input_title.2</Trans></label>
                    <div className={'user-info-rate-rating'}>
                        <div className={'user-info-rate-rating-stars'}>
                            {[...Array(5)].map((_, i) => {
                                return <img
                                    onClick={() => {
                                        setRateValue(i + 1)
                                    }}
                                    src={rateValue > i ? starSelectIcon : starIcon}
                                    key={i}
                                    alt={'star'}
                                    className={'user-info-rate-rating-stars-item'}/>
                            })}
                        </div>
                        <label className={'user-info-rate-rating-value'}>{rateValue.toFixed(1)}</label>
                    </div>
                </div>
                <div className={'user-info-comment'}>
                    <label><Trans>af_awreviewpage.input_title.3</Trans></label>
                    <TextArea className={'user-info-comment-text-area'} onChange={(e) => setComment(e)}/>
                    {commentError && <span className={'input-tips'}>{commentError}</span>}
                </div>
            </div>

            <ConfirmButton className="confirm-button" title={<Trans>af_awreviewpage.button</Trans>} absolute={false} action={() => {
                // navigate('/activate-warranty-step-3')
                if (!email || !username) {
                    Toast.show({
                        content: 'input error'
                    })
                    return
                }
                if (!comment) {
                    setCommentError('Please input your comment')
                    return
                } else  {
                    setCommentError('')
                }

                if (comment.length < appData.review_number) {
                    setCommentError('The comment length should be more '+appData.review_number+' characters')
                    return;
                } else {
                    setCommentError('')
                }

                postRequest('/userapi/update_user_info?user_id=' + appData.user_id, {
                    email: email,
                    username: username
                }).then(res => {
                    postRequest('/userapi/commit_review?act_id=' + appData.act_id, {
                        rating: rateValue,
                        content: comment
                    }).then(res => {
                        const _appData = {...appData}
                        _appData.comment = comment
                        _appData.review_id = res.review.id
                        _appData.rate = rateValue
                        _appData.email = email
                        _appData.username = username
                        setAppData(_appData)
                        console.log(_appData)
                        if (rateValue < appData.config.review.rating) {

                            navigate('/unfinish-success')

                        } else {

                            navigate('/activate-warranty-step-3')
                        }

                    })
                })


            }}/>
        </div>

    )
}

export default ActivateWarrantyStep2
