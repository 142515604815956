import React, {useState, createContext} from 'react';

export const SearchContext = createContext();

export const SearchProvider = props => {
    const [searchText, setSearchText] = useState(false);

    return (
        <SearchContext.Provider value={[searchText, setSearchText]}>
            {props.children}
        </SearchContext.Provider>
    );
}
