import {gql} from "@apollo/client";

export const QUERY_PRUDUCT = gql`
    query QUERY_PRUDUCT($asin: bpchar = "") {
        product: lingxing_amzn_product(where: {asin: {_eq: $asin}}) {
            asin
            data
            sid
        }
    }
`
export const QUERY_USER = gql`
    query QUERY_USER($email: String = "") {
        user_info: core_user(where: {email: {_eq: $email}}, limit: 1) {
            id
            phone
            realname
            username
            updated_at
            email
            created_at
            country_code
            amzn_user_id
            is_verified
            register_time
            sex
        }
    }
`

export const INSER_USER = gql`
    mutation INSER_USER($email: String = "", $username: String = "") {
        result:insert_core_user(objects: {email: $email, username: $username}) {
            user:returning {
                id
                username
                email
            }
        }
    }
`

export const QUERY_WARRANTY_ACTIVATION = gql`
    query QUERY_WARRANTY_ACTIVATION($asin: String, $order_id: String, $user_id: uuid) {
        result:core_af_warranty_activation(where: {product_asin: {_eq: $asin}, order_id: {_eq: $order_id}, user_id: {_eq: $user_id}}) {
            order_id
            product_asin
            user_id
            id
            review_id
            status
        }
    }`

export const INSERT_WARRANTY_ACTIVATION = gql`
    mutation INSERT_WARRANTY_ACTIVATION( $order_id: String, $asin: String, $user_id: uuid,$status: String) {
        result:insert_core_af_warranty_activation(objects: {order_id: $order_id, product_asin: $asin, user_id: $user_id,status: $status}) {
            af_warranty_activation:returning {
                id
            }
        }
    }`

export const UPDATE_WARRANTY_ACTIVATION = gql`
    mutation UPDATE_WARRANTY_ACTIVATION($id: uuid = "", $status: String = "", $review_id: uuid = "", $order_id: String = "", $product_asin: String = "", $user_id: uuid = "") {
        update_core_af_warranty_activation_by_pk(pk_columns: {id: $id}, _set: {status: $status, review_id: $review_id, product_asin: $product_asin, order_id: $order_id, user_id: $user_id}) {
            review_id
            product_asin
            order_id
            id
            user_id
            status
            updated_at
        }
    }

`
