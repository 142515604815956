import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import AppLayout from './pages/layout/index';
import 'lib-flexible'
import { OrderProvider } from './providers/OrderProvider';
import { SearchProvider } from "./providers/SearchProvider";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider
} from "@apollo/client";
import './utils/i18n'
import { AppDataProvider } from "./providers/AppDataProvider";
import { gqlURL } from './env';
const client = new ApolloClient({
    uri: gqlURL + '/v1/graphql',
    cache: new InMemoryCache()
});


ReactDOM.render(
    <ApolloProvider client={client}>
        <AppDataProvider>
            <OrderProvider>
                <SearchProvider>
                    <BrowserRouter>
                        <AppLayout />
                    </BrowserRouter>
                </SearchProvider>
            </OrderProvider>
        </AppDataProvider>
    </ApolloProvider>

    ,
    document.getElementById('root')
);

