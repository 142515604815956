import './index.scss'
import React, {useContext, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {Divider} from "antd-mobile";
import ConfirmButton from "../../component/ConfirmButton";
import {Trans} from "react-i18next";
import {AppDataContext} from "../../providers/AppDataProvider";
import {postRequest} from "../../utils/axios";

const SubmitAddress = () => {
    const navigate = useNavigate()
    const [appData] = useContext(AppDataContext)
    // const [countryPickerVisible, setCountryPickerVisible] = useState(false);

    const [address1, setAddress1] = useState();
    const [address2, setAddress2] = useState();
    const [postcode, setPostcode] = useState();
    const [phone, setPhone] = useState();
    const [country, setCountry] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    if (!appData.config) {
        navigate('/')
    }
    return (
        <div className={'submit-address-box'}>
            <div className="top-view">
                <div className="title">
                    <div><Trans>af_gawconfirminfopage.title</Trans></div>
                    <div><Trans>af_gawconfirminfopage.body</Trans></div>
                </div>
                <Divider className={'divider'}/>
                <div className={'user-info'}>
                    <div className={'user-info-item'}>
                        <label>Name</label><span>{appData.username}</span>
                    </div>
                    <div className={'user-info-item'}>
                        <label>Email</label><span>{appData.email}</span>
                    </div>
                </div>
            </div>
            <div className="address-form">
                {/*<div className="input-box">*/}
                {/*    <label>Country/Region <span>*</span></label>*/}
                {/*    <div onClick={() => setCountryPickerVisible(true)}*/}
                {/*         className="input-item picker-result">Australia <img src={downArrowIcon} alt=""/></div>*/}
                {/*    <Picker*/}
                {/*        columns={[[{label: 'china', value: 'china'}]]}*/}
                {/*        visible={countryPickerVisible}*/}
                {/*        onClose={() => {*/}
                {/*            setCountryPickerVisible(false)*/}
                {/*        }}*/}
                {/*        confirmText={<span style={{color: '#FFA115'}}>确认</span>}*/}
                {/*        value={country}*/}
                {/*        onConfirm={setCountry}*/}
                {/*        onSelect={(val, extend) => {*/}
                {/*            console.log('onSelect', val, extend.items)*/}
                {/*        }}*/}
                {/*    />*/}

                {/*</div>*/}
                <div className="input-box">
                    <label>Country/Region <span>*</span></label>
                    <input className="input-item" placeholder="Country/Region"
                           onChange={(e) => setCountry(e.target.value)}/>
                </div>

                <div className="input-box">
                    <label>Address <span>*</span></label>
                    <input className="input-item" placeholder="Stree address,P.O.box,company name,c/o"
                           onChange={(e) => setAddress1(e.target.value)}/>
                    <input className="input-item" style={{marginTop: 7}} placeholder={"Apt,Suite,Unit,Building,Floor"}
                           onChange={(e) => setAddress2(e.target.value)}/>
                </div>
                <div className="input-box">
                    <label>Postcode <span>*</span></label>
                    <input className="input-item" placeholder="please enter your postcode"
                           onChange={(e) => setPostcode(e.target.value)}/>
                </div>
                <div className="input-box">
                    <label>Phone Number <span>*</span></label>
                    <input className="input-item" placeholder="please enter your phone number"
                           onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <div className="input-box">
                    <label>City/Suburb <span>*</span></label>
                    <input className="input-item" placeholder="City/Suburb " onChange={(e) => setCity(e.target.value)}/>
                </div>
                <div className="input-box">
                    <label>State/Territory <span>*</span></label>
                    <input className="input-item" placeholder="State/Territory "
                           onChange={(e) => setState(e.target.value)}/>
                </div>
                {/*<div className="input-box">*/}
                {/*    <label>City/Suburb <span>*</span></label>*/}
                {/*    <div onClick={() => setCountryPickerVisible(true)}*/}
                {/*         className="input-item picker-result placeholder">Choose city or suburb <img src={downArrowIcon}*/}
                {/*                                                                                alt=""/></div>*/}
                {/*    <Picker*/}
                {/*        columns={[[{label: 'china', value: 'china'}]]}*/}
                {/*        visible={countryPickerVisible}*/}
                {/*        onClose={() => {*/}
                {/*            setCountryPickerVisible(false)*/}
                {/*        }}*/}
                {/*        confirmText={<span style={{color: '#FFA115'}}>确认</span>}*/}
                {/*        value={country}*/}
                {/*        onConfirm={setCountry}*/}
                {/*        onSelect={(val, extend) => {*/}
                {/*            console.log('onSelect', val, extend.items)*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="input-box">*/}
                {/*    <label>State/Territory<span>*</span></label>*/}
                {/*    <div onClick={() => setCountryPickerVisible(true)}*/}
                {/*         className="input-item picker-result placeholder">Choose state or territory <img src={downArrowIcon}*/}
                {/*                                                                                alt=""/></div>*/}
                {/*    <Picker*/}
                {/*        columns={[[{label: 'china', value: 'china'}]]}*/}
                {/*        visible={countryPickerVisible}*/}
                {/*        onClose={() => {*/}
                {/*            setCountryPickerVisible(false)*/}
                {/*        }}*/}
                {/*        confirmText={<span style={{color: '#FFA115'}}>确认</span>}*/}
                {/*        value={country}*/}
                {/*        onConfirm={setCountry}*/}
                {/*        onSelect={(val, extend) => {*/}
                {/*            console.log('onSelect', val, extend.items)*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <ConfirmButton className="confirm-button" title={<Trans>af_gawconfirminfopage.button</Trans>}
                           action={() => {
                               //navigate('/finish-success')
                               postRequest('/userapi/confirm_address?act_id=' + appData.act_id, {
                                   name: appData.username,
                                   receiver_name: appData.username,
                                   receiver_email: appData.email,
                                   receiver_phone: phone,
                                   street: address1 + address2,
                                   postcode: postcode,
                                   country_code: country,
                                   city: city,
                                   state: state,
                                   full_address: country + state + city + address1 + address2
                               }).then(res => {
                                   navigate('/finish-success')
                               })
                           }}/>
        </div>

    )
}

export default SubmitAddress
