import {Image, Input, Mask} from "antd-mobile";
import i18n, {t} from "i18next";
import React, {useContext, useState} from 'react';
import {Trans} from "react-i18next";
import 'video.js/dist/video-js.css';
import video from "../../imgs/1643512711525771.mp4";
import deleteIcon from "../../imgs/Delete.png";
import findAmazonOrderImg from "../../imgs/find-amazon-order-img.png";
import findOrderIdIcon from "../../imgs/find-orderid-icon.png";
import questionMarkIcon from "../../imgs/question-mark.png";
import warnningIcon from "../../imgs/warnning.png";
import {OrderContext} from "../../providers/OrderProvider";
import EmailInput from "../EmailInput";
import './index.scss';
import {AppDataContext} from "../../providers/AppDataProvider";

const OrderIdInput = (props) => {
    const [, setOrderId] = useContext(OrderContext);
    const [appData, _] = useContext(AppDataContext);
    const [currentInput, setCurrentInput] = useState(0)
    const [videoNode, setVideoNode] = useState()
    const [inputValues, setInputValues] = useState({})
    const [showOrderImageSlide, setShowOrderImageSlide] = useState(false)
    const [showGuideVideo, setShowGuideVideo] = useState(false)
    const [amzSite] = useState({
        en: 'https://www.amazon.com/',
        it: 'https://www.amazon.it/',
        ja: 'https://www.amazon.co.jp/',
        fr: 'https://www.amazon.fr/',
        de: 'https://www.amazon.de/',
        sp: 'https://www.amazon.es/',

    })
    const videoJsOptions = {
        autoplay: true,
        controls: true,
        sources: [{
            src: video,
            type: 'video/mp4'
        }]
    }
    var inputs = [];
    // var inputValues = {}

    const onDelete = (key, i) => {

        if (key.keyCode === 8) {
            // console.log('delete')
            // console.log(i)
            const values = inputValues
            delete values[3]
            delete values[11]
            setInputValues(values)
            if (i === 18) {
                const values = inputValues
                values[i] = ""
                setInputValues(values)

            }
            if (inputs[currentInput - 1]) {
                inputs[currentInput].blur()
                inputs[currentInput - 1].clear()
                inputs[currentInput - 1].focus()
                setCurrentInput(currentInput - 1);
            }

        }
    }

    const onFocus = (i) => {
        if (i > 3 && i < 11) {
            setCurrentInput(i - 1)
        } else if (i > 11) {
            setCurrentInput(i - 2)
        } else {
            setCurrentInput(i)
        }

    }


    const onChange = (v, i) => {

        var values = inputValues
        console.log(v)

        v = v.replace(/\r\n/g, "")
        v = v.replace(/\n/g, "");

        v = v.replace(/\s/g, "");
        if (v.length === 19) {
            for (var j = 0; j < 19; j++) {
                values[j] = v[j]
                inputs[17].focus()
                setCurrentInput(17);
            }

            setInputValues(values)
            const orderId = Object.values(values).join('')
            console.log('input done', orderId)
            props.inputFinish(orderId)
            setOrderId(orderId)
            return
        }
        if (v) {
            if (currentInput < 17) {
                inputs[currentInput + 1].focus()
                setCurrentInput(currentInput + 1);
            }
        }
        values[i] = v ? v.substr(v.length - 1, 1) : v
        setInputValues(values)
        console.log(values)
        const orderId = Object.values(values).join('')
        console.log(orderId.length)
        if (orderId.length === 17) {
            values[3] = '-'
            values[11] = '-'
            const orderId = Object.values(values).join('')
            console.log('input done', orderId)
            props.inputFinish(orderId)
            setOrderId(orderId)
        }

    }


    return (
        <div className={'activate-warranty-orderinput'}>
            <label><Trans>af_homepage.awcard.input_hint</Trans>:</label>
            <div className={'activate-warranty-orderinput-inputbox'}>
                {[...Array(19)].map((_, i) => {
                    if ([3, 11].indexOf(i) !== -1) {
                        return <span key={i}>-</span>
                    } else {
                        return <Input type="tel"
                                      key={i}
                                      value={inputValues[i]}
                                      onChange={(v) => onChange(v, i)}
                                      onFocus={() => onFocus(i)}
                                      onKeyDown={(key) => onDelete(key, i)}
                                      ref={(ref) => {
                                          inputs.push(ref)
                                      }}
                        />
                    }
                })}
                <Input type="tel" style={{width: 0, height: 0}}
                       ref={(ref) => {
                           inputs.push(ref)
                       }}
                />
            </div>
            {false && props.showWarning && <div className={'activate-warranty-orderinput-warnning'}>
                <Image src={warnningIcon}/>{appData.adminConfig?.error_msg ||  <Trans>incorrectorderpopcard.title</Trans>}
            </div>}
            {props.showWarning && <EmailInput/>}
            <div className={'activate-warranty-orderinput-tips'}>
                <div className={'activate-warranty-orderinput-tips-left'} onClick={() => {
                    const url = amzSite[i18n.language]
                    window.open(url + 'gp/css/order-history', '_blank', 'noopener,noreferrer')
                }}>
                    <Image src={findOrderIdIcon}/> {t('af_homepage.awcard.url.0')}
                </div>
                <div onClick={() => {
                    // setShowOrderImageSlide(!showOrderImageSlide)
                    setShowGuideVideo(true)
                }} className={'activate-warranty-orderinput-tips-right'}>
                    <Image src={questionMarkIcon}/> {t('af_homepage.awcard.url.1')}
                </div>
            </div>
            {showOrderImageSlide && <Image className={"activate-warranty-orderinput-image"} src={findAmazonOrderImg}/>}

            <Mask
                color='black'
                opacity={0.6}
                visible={showGuideVideo}>
                <div className="video-box">
                    <Image src={deleteIcon} className={'close-video'} onClick={() => setShowGuideVideo(false)}/>
                    {showGuideVideo && <video
                        className={'video'}
                        autoPlay={true}
                        controls
                        webkit-playsinline="true"
                        playsInline={true}
                        // src={appData.video_urls && appData.video_urls[i18n.language] ?  appData.video_urls[i18n.language].url: ''}
                        onEnded={() => setShowGuideVideo(false)}
                    >
                        <source type="video/mp4" src={appData.video_urls[i18n.language].url}/>
                        Sorry, your browser doesn't support embedded videos.
                    </video>}


                </div>
            </Mask>

            {/*{showOrderImageSlide && <OrderImageSlide/>}*/
            }

        </div>

    )
}

export default OrderIdInput
