import './index.scss'
import React, {useContext, useState} from 'react'
import downArrowRedIcon from '../../imgs/mini-down-arrow-red.png'
import filterIcon from '../../imgs/filter.png'
import giftCartIcon from '../../imgs/gift-cart.png'
import circleSelectedIcon from '../../imgs/circle-selected.png'
import circleIcon from '../../imgs/circle.png'
import {Image, Popup} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import {Trans} from "react-i18next";
import {t} from "i18next";
import {AppDataContext} from "../../providers/AppDataProvider";
import {postRequest} from "../../utils/axios";
import {useLazyQuery} from "@apollo/client";
import {QUERY_GIFTS, QUERY_GIFTS_WITH_TYPE} from "../ActivateWarrantyStep3/graphql";

const ChooseGift = () => {

    const navigate = useNavigate();
    const [appData, setAppData] = useContext(AppDataContext)
    if (!appData.config) {
        navigate('/')
    }
    const [currentGiftType, setCurrentGiftType] = useState('all');
    const [selectedGifts, setSelectedGifts] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false);

    const [gifts, setGifts] = useState(appData.allGifts);


    const giftTypes = [
        'PayPal',
        'Gift Card',
        'Accessory'
    ];
   const [queryGifts] =  useLazyQuery(QUERY_GIFTS_WITH_TYPE, {
        variables: {
            type: currentGiftType
        },
        onCompleted: (data) => {
            setGifts(data.result)
        }
    })

    const giftClick = (index) => {
        const _appData = {...appData}
        const _gifts = gifts
        _gifts[index]["selected"] = !_gifts[index]["selected"]
        _gifts[index]['quantity'] = 1
        const _selectedGifts = [...selectedGifts]
        const idx = selectedGifts.findIndex(e => _gifts[index].id === e.id)
        console.log(idx)
        if (idx === -1) {
            _selectedGifts.push(_gifts[index])
        } else {
            _selectedGifts.splice(idx, 1)
        }
        setSelectedGifts(_selectedGifts)
        _appData.selectedGifts = _selectedGifts
        var total_gifts_price = 0
        for (const i in _selectedGifts) {
            total_gifts_price += _selectedGifts[i].price
        }
        _appData.total_gifts_price = total_gifts_price.toFixed(2)
        setAppData(_appData)
        setGifts(_gifts)
        console.log(_selectedGifts)
    }

    return (
        <div className={'choose-gift-box'}>
            <div className={'top-view'}>
                <div className={"order-options"}>
                    <div className={"item"}><Trans>af_giftcart.sort.0</Trans><img src={downArrowRedIcon} alt=""/>
                    </div>
                    <div className={"item"}><Trans>af_giftcart.sort.1</Trans> <img src={downArrowRedIcon} alt=""/>
                    </div>
                    <div className={"item"}><Trans>af_giftcart.sort.2</Trans> <img src={downArrowRedIcon} alt=""/>
                    </div>
                </div>
                <div className={'top-view-gift-type-options'}>
                    {giftTypes.map((type) => {
                        return <div
                            key={type}
                            className={currentGiftType === type ? 'item item-selected' : 'item'}
                            onClick={() => {
                                setSelectedGifts([])
                                setCurrentGiftType(type)
                                queryGifts()
                            }}>{type}
                        </div>
                    })}
                    <div className={'filter'} onClick={() => setPopupVisible(true)}>
                        <span/>
                        <Image src={filterIcon}/>
                    </div>
                </div>

            </div>
            <div className={'gift-list'}>
                {gifts.map((gift, index) => {

                        return <div className={'gift-item'} key={index} onClick={() => giftClick(index)}>
                            <Image className={"selected-icon"} src={gift.selected ? circleSelectedIcon : circleIcon}/>
                            <Image src={gift.icon_url} className={'gift-image'} fit={"scale-down"}/>
                            <div className={'gift-title'}>
                                {gift.name}
                            </div>
                            <div className={'gift-price'}>${gift.price}</div>
                        </div>


                })}
            </div>

            <div className={'bottom-view'}>
                <div className={'price-view'}>
                    <div><Trans>af_giftcart.tab.item.0</Trans>: <span
                        style={{color: "#F33A28"}}>${appData.total_gifts_price}</span></div>
                    <div>
                        <Trans>af_giftcart.tab.item.1</Trans>: <span>${(appData.config.review.star_value * appData.rate).toFixed(2)}</span>
                    </div>
                </div>
                <div className={'button-view'} >
                    <div className={'cart-view'} onClick={() => navigate('/gift-cart')}><Image className={'cart-icon'} src={giftCartIcon}/>
                        <span>{selectedGifts.length}</span>
                    </div>
                    <div className={'ok-btn'} onClick={() => {
                        //navigate(-1)
                        postRequest('/userapi/select_gift?act_id=' + appData.act_id, {
                            gifts: selectedGifts
                        }).then(res => {
                            const _appData = {...appData}
                            _appData.gift_order_id = res.gift_order_id
                            setAppData(_appData)
                            navigate(-1)
                        })
                    }}><Trans>af_giftcart.tab.button</Trans></div>
                </div>
            </div>

            <Popup
                visible={popupVisible}
                onMaskClick={() => {
                    setPopupVisible(false)
                }}
                position='right'
                bodyStyle={{
                    minWidth: '330px',
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px"
                }}
            >
                <div className={'popup-options'}>
                    <div className={'type-options'}>
                        <div className={"options-title"}><Trans>af_giftcart.filter.type.title</Trans></div>
                        <div className={"items"}>
                            <span><Trans>af_giftcart.filter.type.content.0</Trans></span>
                            <span><Trans>af_giftcart.filter.type.content.1</Trans></span>
                            <span><Trans>af_giftcart.filter.type.content.2</Trans></span>

                        </div>
                    </div>
                    <div className={'price-options'}>
                        <div className={"options-title"}><Trans>af_giftcart.filter.range.title</Trans></div>
                        <div className={"items"}>
                            <input placeholder={t('af_giftcart.filter.range.content.0')}/>
                            <span>-</span>
                            <input placeholder={t('af_giftcart.filter.range.content.1')}/>
                        </div>
                    </div>
                </div>
                <div className={'bottom-button-view'}>
                    <div className={'cart-view'}>
                        <Trans>af_giftcart.filter.button.0</Trans>
                    </div>
                    <div className={'ok-btn'}><Trans>af_giftcart.filter.button.1</Trans></div>
                </div>
            </Popup>


        </div>

    )
}

export default ChooseGift
