import { Input, Toast } from "antd-mobile";
import React, {useContext, useEffect, useState} from 'react';
import { Trans, useTranslation } from "react-i18next";
import { AppDataContext } from "../../providers/AppDataProvider";
import { postRequest } from "../../utils/axios";
import './index.scss';

const EmailInput = (props) => {
    const { t } = useTranslation()
    const [appData,setAppData] = useContext(AppDataContext)
    const [email, setEmail] = useState()
    useEffect(()=>{
        const _appD = {...appData}
        _appD.user_email = email
        console.log('email',email)
        console.log('_appD',_appD)
        setAppData(_appD)
    },[email])
    return (
        <div className="email-input">
            {false && <div className={'email-input-title'}><Trans>incorrectorderpopcard.body</Trans></div>}
            <div className={'email-input-email'} style={{color:'red'}}><Trans>incorrectorderpopcard.tip</Trans> {appData.adminConfig?.email||appData.seller_email}</div>
            <div className={'email-input-input-box'}>
                <Input type="email" onChange={(e) => setEmail(e)}
                    placeholder={t('incorrectorderpopcard.inputemail_hint')} />
                <button onClick={() => {
                    console.log(appData)
                    postRequest('/userapi/upsert_failed_act_user?failed_act_id=' + appData.failed_act_id+'&aid='+appData.adminConfig.user_id, {
                        email: email.replace(' ', ''),
                        c_email: appData.adminConfig.email

                    }).then(res => {
                        if (res.code == -1) {
                            Toast.show({
                                content: res.msg,
                            })
                        } else {
                            Toast.show({
                                content: appData.adminConfig.error_msg,
                            })
                        }
                        
                    }).catch(err => {
                        Toast.show({
                            content: 'email error',
                            icon: 'fail'
                        })
                    })
                }}><Trans>incorrectorderpopcard.button</Trans></button>
            </div>


        </div>

    )
}

export default EmailInput
