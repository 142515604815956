import {gql} from "@apollo/client";

export const QUERY_ORDER = gql`
    query QUERY_ORDER($order_id: bpchar) {
        order: lingxing_amzn_order(where: {order_id: {_eq: $order_id}}, limit: 1) {
            order_id
            sid
            data
        }
    }
`
export const QUERY_VIDEO = gql`
    query QUERY_VIDEO($name: String) {
          video:marketing_group(where: {name: {_eq: $name}}, limit: 1) {
                name
                data
          }
    }
`
