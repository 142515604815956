import './index.scss'
import React from 'react'

const ConfirmButton = (props) => {
    return (
        <div
            className={props.absolute ? 'activate-warranty-btn activate-warranty-btn-absolute' : 'activate-warranty-btn '+props.className}
            onClick={() => props.action()}>{props.title}</div>
    )
}

export default ConfirmButton
