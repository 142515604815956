import './index.scss'
import React, {useContext, useState} from 'react'
import HomeGuide from "../../component/HomeGuide";
import ConfirmButton from "../../component/ConfirmButton";
import {useNavigate} from "react-router-dom";
import starIcon from "../../imgs/star.png";
import starSelectIcon from "../../imgs/start_select.png";
import giftPriceIcon from "../../imgs/gift-price.png";
import giftIcon from "../../imgs/gift.png";
import {Image, Mask} from "antd-mobile";
import cartIcon from '../../imgs/cart.png'
import {Trans} from "react-i18next";
import warningIcon from "../../imgs/warning.png";
import {AppDataContext} from "../../providers/AppDataProvider";
import {useQuery} from "@apollo/client";
import {QUERY_GIFTS} from "./graphql";
import {postRequest} from "../../utils/axios";
import copy from "copy-to-clipboard";

const ActivateWarrantyStep3 = () => {
    const navigate = useNavigate()
    const [rateValue, setRateValue] = useState(5);
    const [jumpToGuideVisible, setJumpToGuideVisible] = useState(false);
    const [appData, setAppData] = useContext(AppDataContext)
    if (!appData.config) {
        navigate('/')
    }
    const [gifts, setGifts] = useState([])
    useQuery(QUERY_GIFTS, {
        onCompleted: (data) => {
            setGifts(data.result)
            var gifts = [];
            for (const i in data.result) {
                gifts.push({
                    name: data.result[i].name,
                    icon_url: data.result[i].icon_url,
                    selected: false,
                    price: data.result[i].price,
                    id: data.result[i].id
                })
            }
            const _appData = {...appData}
            _appData.allGifts = gifts
            setAppData(_appData)
        }
    })


    // useEffect(() => {
    //     // exit early when we reach 0
    //     if (!timeLeft) {
    //         return jumpToUrl();
    //
    //     }
    //
    //     // save intervalId to clear the interval when the
    //     // component re-renders
    //     const intervalId = setInterval(() => {
    //         setTimeLeft(timeLeft - 1);
    //     }, 1000);
    //
    //     // clear interval on re-render to avoid memory leaks
    //     return () => clearInterval(intervalId);
    //     // add timeLeft as a dependency to re-rerun the effect
    //     // when we update it
    // }, [timeLeft]);
    return (
        <div className={'box'}>
            <HomeGuide title={<Trans>af_gawchoosegiftpage.title</Trans>}
                       desc={<Trans>af_gawchoosegiftpage.body</Trans>}/>
            <div className={'user-info'}>
                <div className={'user-info-item'}>
                    <label><Trans>af_gawchoosegiftpage.input_title.0</Trans></label><span>{appData.username}</span>
                </div>
                <div className={'user-info-item'}>
                    <label><Trans>af_gawchoosegiftpage.input_title.1</Trans></label><span>{appData.email}</span>
                </div>
                <div className={'user-info-item'}>
                    <label><Trans>af_gawchoosegiftpage.input_title.2</Trans></label><span>{appData.comment}</span>

                </div>
                <div className={'user-info-rate'}>
                    <label className={'user-info-rate-title'}><Trans>af_gawchoosegiftpage.input_title.3</Trans></label>
                    <div className={'user-info-rate-rating'}>
                        <div className={'user-info-rate-rating-stars'}>
                            {[...Array(5)].map((_, i) => {
                                return (
                                    <div key={i} className={'user-info-rate-rating-stars-item'} onClick={() => {
                                        setRateValue(i + 1)
                                    }}>
                                        <span className={"user-info-rate-rating-stars-item-price"}
                                              style={{color: rateValue > i ? "#000000" : "#929292"}}>{appData.config ? appData.config.review.star_value : '$10'}</span>
                                        <img
                                            src={rateValue > i ? starSelectIcon : starIcon}
                                            className={''} alt={"star"}/>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={'user-info-rate-rating-gift'}>
                            <span
                                className={'user-info-rating-gift-price'}>${rateValue * (appData.config ? appData.config.review.star_value : '10')}</span>
                            <Image src={giftPriceIcon}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'user-choose-gift'} onClick={() => navigate('/choose-gift')}>
                <div className={'user-choose-gift-title'}>
                    <Image src={giftIcon}/><Trans>af_gawchoosegiftpage.giftcard</Trans>
                </div>
                <div className={"user-choose-gift-gifts"}>
                    {gifts.map((gift, index) => {
                        if (index < 5) {
                            return <Image key={gift.id} src={gift.icon_url} fit={"scale-down"}/>
                        }

                    })}
                </div>

            </div>

            {appData.selectedGifts && <div className={'user-gift-cart'} onClick={() => navigate('/gift-cart')}>
                <div className={'user-gift-cart-title'}>
                    <div className={'user-gift-cart-title-icon'}>
                        <Image src={cartIcon}/>
                        <span>{appData.selectedGifts.length}</span>
                    </div>
                    <Trans>af_gawchoosegiftpage.cartcard.title</Trans>
                </div>
                <div className={'user-gift-cart-box'}>

                    <div className={"user-gift-cart-box-gifts"}>
                        {appData.selectedGifts && appData.selectedGifts.map((gift, index) => {
                            if (index < 3) {
                                return <Image key={gift.id} src={gift.icon_url} fit={"scale-down"}/>
                            }

                        })}
                    </div>
                    <div className={"user-gift-cart-box-infotext"}>
                        <div>
                            <Trans>af_gawchoosegiftpage.cartcard.item.0</Trans>: <span>${rateValue * (appData.config ? appData.config.review.star_value : '10')}</span>
                        </div>
                        <div><Trans>af_gawchoosegiftpage.cartcard.item.1</Trans>: <span
                            className={'user-gift-cart-box-infotext-bold'}>${appData.total_gifts_price}</span></div>
                        <div>
                            <Trans>af_gawchoosegiftpage.cartcard.item.2</Trans>: <span>{appData.selectedGifts.length}</span>
                        </div>
                    </div>
                </div>
                <div className={'user-gift-cart-box-tips'}>
                    Not enough money (${rateValue * 10}) for gift ($80)!
                </div>
            </div>}

            <ConfirmButton className={'confirm-button'} title={rateValue<5?<Trans>af_awuserinfopage.button</Trans>:<Trans>af_gawchoosegiftpage.button</Trans>}
                           action={() => {
                               if (rateValue == 5) {
                                   postRequest('/userapi/posting_to_amazon?act_id=' + appData.act_id).then(() => {
                                       copy(appData.comment)
                                       window.open('https://www.amazon.com/review/review-your-purchases/edit?asin=' + appData.asin + '&channel=YAcc-wr&ref=ppx_yo2_dt_b_rev_prod', '_blank', 'noopener,noreferrer')
                                       setJumpToGuideVisible(true)
                                   })
                               } else {
                                   navigate('/unfinish-success')
                               }


                           }}/>


            <Mask
                color='white'
                opacity={0.8}
                visible={jumpToGuideVisible}
                onMaskClick={() => setJumpToGuideVisible(false)}>
                <div className={"jump-guide-box"}>
                    <div className={"warning-title"}>
                        <Image src={warningIcon}/>Dear {appData.username}?
                    </div>
                    <div className={"warning-desc"}>
                        Have you copied and pasted your review on Amazon?Have you copied and pasted your review on
                        Amazon?
                    </div>
                    <div className={"warning-buttons"}>
                        <button className={'again-button'} onClick={() => {
                            window.open('https://www.amazon.com/review/review-your-purchases/edit?asin=B099DVP4WQ&channel=YAcc-wr&ref=ppx_yo2_dt_b_rev_prod', '_blank', 'noopener,noreferrer')
                        }}>Try again
                        </button>
                        <button className={'yes-button'} onClick={() => {
                            // navigate('/submit-address')
                            postRequest('/userapi/posted_to_amazon?act_id=' + appData.act_id).then(res => {
                                navigate('/submit-address')
                            })
                        }}>YES
                        </button>

                    </div>
                </div>
            </Mask>
        </div>

    )
}

export default ActivateWarrantyStep3
