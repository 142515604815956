import './index.scss'
import React, { useContext, useState } from 'react'
import giftDemoImage from '../../imgs/giftDemo.png'
import giftCartIcon from '../../imgs/gift-cart.png'
import deleteIcon from '../../imgs/delete-icon.png'
import downArrowIcon from '../../imgs/down-arrow.png'
import { Image, Popup } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { AppDataContext } from "../../providers/AppDataProvider";

const GiftCart = () => {
    const navigate = useNavigate();
    const [appData, setAppData] = useContext(AppDataContext);
    const [selectedGifts, setSelectedGifts] = useState(appData.selectedGifts);
    const [popupVisible, setPopupVisible] = useState(false);


    return (
        <div className={'gift-cart-box'}>

            <div className={'cart-list'}>
                {false && <div className={'cart-item cart-item-mask'}>
                    <Image src={deleteIcon} className={"delete-icon"} />
                    <Image className={'gift-image'} src={giftDemoImage} fit={"scale-down"} />
                    <div className={'gift-info'}>
                        <div className={'gift-title'}>Projector backpack</div>
                        <div className={'gift-spec'} onClick={() => setPopupVisible(true)}>[black]basic <img
                            src={downArrowIcon} alt="" /></div>
                        <div className={'gift-price'}>
                            <span>$49.0</span>
                            <div className="take-up">
                                Take up
                            </div>
                            {false && <div className={'number-edit-view'}>
                                <span>-</span>
                                <span>10</span>
                                <span>+</span>
                            </div>}
                        </div>
                    </div>
                </div>}
                {selectedGifts && selectedGifts.map((item, index) => {
                    return <div key={index} className={'cart-item'}>
                        <Image onClick={() => {
                            const _selectedGifts = [...selectedGifts]
                            _selectedGifts.splice(index, 1)
                            setSelectedGifts(_selectedGifts)
                            const _appData = appData
                            _appData.selectedGifts = _selectedGifts
                            setAppData(_appData)
                        }} src={deleteIcon} className={"delete-icon"} />
                        <Image className={'gift-image'} src={item.icon_url} fit={"scale-down"} />
                        <div className={'gift-info'}>
                            <div className={'gift-title'}>{item.name}</div>
                            {/*<div className={'gift-spec'} onClick={() => setPopupVisible(true)}>[black]basic <img*/}
                            {/*    src={downArrowIcon} alt=""/></div>*/}
                            <div className={'gift-price'}>
                                <span>${item.price}</span>
                                {/*<div className="take-up">*/}
                                {/*    Take up*/}
                                {/*</div>*/}
                                <div className={'number-edit-view'}>
                                    <span>-</span>
                                    <span>1</span>
                                    <span>+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                })}

            </div>

            <div className={'bottom-view'}>
                <div className={'price-view'}>
                    <div>Total: <span style={{ color: "#F33A28" }}>${appData.total_gifts_price}</span></div>
                    <div>Balance: <span>${(appData.config.review.review_number * appData.rate).toFixed(2)}</span></div>
                </div>
                <div className={'button-view'}>
                    <div className={'cart-view'}>
                        <Image className={'cart-icon'} src={giftCartIcon} />
                        <span>{selectedGifts.length}</span>
                    </div>
                    <div className={'ok-btn'} onClick={() => navigate(-1)}>OK</div>
                </div>
            </div>


            <Popup
                visible={popupVisible}
                onMaskClick={() => {
                    setPopupVisible(false)
                }}
                position='bottom'
                bodyStyle={{
                    minHeight: '430px',
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px"
                }}
            >

                <div className={'popup-gift-spec'}>
                    <Image src={deleteIcon} className={"close-icon"} onClick={() => setPopupVisible(false)} />
                    <div className="gift">
                        <Image src={giftDemoImage} fit={"scale-down"} className={'image'} />
                        <div className={"gift-title-price"}>
                            <div className={"title"}>Projector backpack</div>
                            <div className={"price"}>$49.00</div>
                        </div>
                    </div>
                    <div className={"specs"}>
                        <div className="title">Specifications</div>
                        <div className={'specs-list'}>
                            <div className="item item-selected">[black]basic</div>
                            <div className="item">[black]basic</div>
                            <div className="item">[black]basic</div>
                            <div className="item">[black]basic</div>
                            <div className="item">[black]basic</div>
                            <div className="item">[black]basic</div>
                            <div className="item">[black]basic</div>
                        </div>
                    </div>
                </div>
                <div className={'bottom-button-view'}>
                    <div className={'cart-view'} onClick={() => setPopupVisible(false)}>
                        Cancel
                    </div>
                    <div className={'ok-btn'} onClick={() => setPopupVisible(false)}>OK</div>
                </div>
            </Popup>
        </div>

    )
}

export default GiftCart
