import { Image } from "antd-mobile";
import React, { useContext, useState } from 'react';
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ConfirmButton from "../../component/ConfirmButton";
import HomeGuide from "../../component/HomeGuide";
import OrderIdInput from "../../component/OrderIdInput";
import video from "../../imgs/1643512711525771.mp4";
import playIcon from '../../imgs/play.png';
import videPosterIcon from '../../imgs/video-poster.png';
import { AppDataContext } from "../../providers/AppDataProvider";
import { postRequest } from "../../utils/axios";
import './index.scss';

const WatchVideo = () => {
    const [videoRef, setVideoRef] = useState()
    const [showPlayBtn, setShowPlayBtn] = useState(true)
    const [showVideo, setShowVideo] = useState(false)
    const [showWarning, setShowWarning] = useState(false)

    const [appData, setAppData] = useContext(AppDataContext)
    const navigate = useNavigate()
    if (!appData.config) {
        navigate('/')
    }

    const orderIdInputFinish = (orderId) => {
        postRequest('/userapi/check_order', {
            order_id: orderId
        }).then(res => {
            console.log(res)
            setShowVideo(true)
            // const _appData = { ...appData }
            // _appData.act_id = res.act.id
            // _appData.order_id = res.act.order_id
            // _appData.asin = res.product.asin
            // _appData.price = res.product.price
            // _appData.icon_url = res.product.icon_url
            // setAppData(_appData)
            // navigate('/activate-warranty-step-1')
        }).catch((err) => {
            if (err.status === 404) {
                console.log(err.data.detail.failed_act.id)

                const _appData = { ...appData }
                _appData.failed_act_id = err.data.detail.failed_act.id
                console.log(_appData)
                setAppData(_appData)
                setShowWarning(true)
            }
            console.log(err)
        })
    }
    return (
        <div className={'watch-video'}>
            <HomeGuide title={<Trans>af_watchvideopage.title</Trans>}
                desc={<Trans>af_watchvideopage.body</Trans>} />
            <OrderIdInput inputFinish={(order_id) => orderIdInputFinish(order_id)} showWarning={showWarning} />
            {showVideo && <div className={'watch-video-box'}>
                {showPlayBtn && <Image className={'watch-video-box-playbtn'} onClick={() => {
                    videoRef.play()
                    setShowPlayBtn(false)
                }} src={playIcon} />}

                <video
                    ref={(ref) => setVideoRef(ref)}
                    poster={videPosterIcon}
                    onPause={() => setShowPlayBtn(true)}
                    controls>
                    <source src={video}
                        type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>

            </div>
            }
            <ConfirmButton title={<Trans>af_watchvideopage.button</Trans>} className={"confirm-button"} />

        </div>

    )
}

export default WatchVideo
