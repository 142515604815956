import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
// import AfterSaleService from "./pages/Home";
import WatchVideo from "./pages/WatchVideo";
import ActivateWarrantyStep1 from "./pages/ActivateWarrantyStep1";
import React from "react";
import ActivateWarrantyStep2 from "./pages/ActivateWarrantyStep2";
import ActivateWarrantyStep3 from "./pages/ActivateWarrantyStep3";
import ChooseGift from "./pages/ChooseGift";
import GiftCart from "./pages/GiftCart";
import SubmitAddress from "./pages/SubmitAddress";
import FinishSuccess from "./pages/FinishSuccess";
import UnfinishSuccess from "./pages/UnfinishSuccess";


const AfterSaleRoutes = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Home />} />
            <Route path={'/watch-video'} element={<WatchVideo />} />
            <Route path={'/activate-warranty-step-1'} element={<ActivateWarrantyStep1 />} />
            <Route path={'/activate-warranty-step-2'} element={<ActivateWarrantyStep2 />} />
            <Route path={'/activate-warranty-step-3'} element={<ActivateWarrantyStep3 />} />
            <Route path={'/choose-gift'} element={<ChooseGift />} />
            <Route path={'/gift-cart'} element={<GiftCart />} />
            <Route path={'/submit-address'} element={<SubmitAddress />} />
            <Route path={'/finish-success'} element={<FinishSuccess />} />
            <Route path={'/unfinish-success'} element={<UnfinishSuccess />} />
        </Routes>
    )
}
export default AfterSaleRoutes
