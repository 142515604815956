import './index.scss'
import React from 'react'
import {Image} from "antd-mobile";
import homeIcon from '../../imgs/tabbars/home-selcted.png'
import homeIconSelected from '../../imgs/tabbars/home-selcted.png'
import newsIcon from '../../imgs/tabbars/news.png'
import meIcon from '../../imgs/tabbars/me.png'
import cartIcon from '../../imgs/tabbars/cart.png'
import {useLocation, useNavigate} from "react-router-dom";


const AFTabBar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {pathname} = location

    const setRouteActive = (value: string) => {
        navigate(value)
    }


    const tabs = [
        {
            key: '/',
            title: 'tab-home',
            img: homeIcon,
            selectedImg: homeIconSelected,
        },
        {
            key: '/news',
            title: 'tab-news',
            img: newsIcon,
            selectedImg: newsIcon,
        },
        {
            key: '/cart',
            title: 'tab-cart',
            img: cartIcon,
            selectedImg: cartIcon,
        },
        {
            key: '/me',
            title: 'tab-me',
            img: meIcon,
            selectedImg: meIcon,
        },
    ]
    return (
        <div className={'tabbar'}>
            {tabs.map((item, index) => (
                <div className={'tabbar-item'} key={item.key} onClick={() => setRouteActive(item.key)}>
                    <Image className={'icon'} src={pathname === item.key ? item.img : item.selectedImg}/>
                    {item.key === '/cart' && <span className={'badge'}>1</span>}
                    <span className={pathname === item.key ? 'title title-selected' : 'title'}>
                        {/*t("homepage.Button_tab."+index)*/}
                        {item.title}
                        </span>
                </div>
            ))}
        </div>
    )
}

export default AFTabBar
