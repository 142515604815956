import {Button, Toast} from "antd-mobile";
import i18n, {t} from "i18next";
import React, {useContext, useEffect, useState} from "react";
import {Trans} from "react-i18next";
import { useLocation, useNavigate} from "react-router-dom";
import AfterServiceCard from "../../component/AfterServiceCard";
import HomeGuide from "../../component/HomeGuide";
import OrderIdInput from "../../component/OrderIdInput";
import {AppDataContext} from "../../providers/AppDataProvider";
import {getRequest, postRequest} from "../../utils/axios";
import "../AfterSaleService/index.scss";
import {useQuery} from "@apollo/client";
import {QUERY_CONFIG} from "./graphql";
import {QUERY_VIDEO} from "../AfterSaleService/graphql";

const Home = () => {
    const [appData, setAppData] = useContext(AppDataContext);
    const location = useLocation()
    console.log(location)
    const [aid] = useState(()=>{
        let params = location.search.split("?")[1];
        if (params) {
            let paramMap = {};
            let paramJson = params.split("&");
            for (var param of paramJson) {
                var k_v = param.split("=");
                if (k_v.length == 2) {
                    paramMap[k_v[0]] = k_v[1];
                }
            }
            return paramMap['aid']
        }
    })
    console.log(aid)
    useEffect(()=>{
        getRequest('https://aftersale.qingtong123.com/api/landing_page/get_landing_set_by_userid?id='+aid)
            .then(res => {
                console.log(res)
                if (res.code === 200) {

                    const _appdata = {...appData}
                    _appdata.adminConfig = res.data
                    setAppData(_appdata)
                }
            })

    },[])

    const navigate = useNavigate();

    const [showWarning, setShowWarning] = useState(false);
    console.log(appData);
    if (!appData.config) {
        // navigate("/");
    }
    useQuery(QUERY_CONFIG, {
        onCompleted: ((data) => {
            const _appData = {...appData}
            _appData.config = data.result[0]["config"]
            _appData.seller_email = data.result[0]["config"]['orderid']['email'].address
            _appData.review_number = data.result[0]["config"]['review'].review_number
            setAppData(_appData)
            // navigate('/after-sale-service')
        })
    });

    useQuery(QUERY_VIDEO, {
        variables: {name: 'default'},
        onCompleted: ((data) => {
            const _appData = {...appData}
            if (data.video[0]["data"]) {
                _appData.video_urls = data.video[0].data
                setAppData(_appData);
            }
        })
    });

    const orderIdInputFinish = (orderId) => {
        const _appData = {...appData};
        _appData.act_id = orderId;
        setAppData(_appData)
        postRequest("/userapi/check_order", {
            order_id: orderId,
        })
            .then((res) => {
                console.log(res);
                const _appData = {...appData};
                _appData.act_id = res.act.id;
                _appData.order_id = res.act.order_id;
                _appData.asin = res.product.asin;
                _appData.price = res.product.price;
                _appData.icon_url = res.product.icon_url;
                setAppData(_appData);
                // navigate("/activate-warranty-step-1");
            })
            .catch((err) => {
                if (err.status === 404) {
                    console.log(err.data.detail.failed_act.id);

                    const _appData = {...appData};
                    _appData.failed_act_id = err.data.detail.failed_act.id;
                    console.log(_appData);
                    setAppData(_appData);
                    setShowWarning(true);
                }
                console.log(err);
            });
    };
    return (
        <div className={"home"}>
            <HomeGuide
                title={<Trans>af_homepage.head.title</Trans>}
                desc={<Trans>af_homepage.head.body</Trans>}
            />
            {false && <AfterServiceCard
                title={<Trans>af_homepage.watchvideocard.title</Trans>}
                desc={<Trans>af_homepage.watchvideocard.body</Trans>}
                action={() => navigate("/watch-video")}
            />}
            <div className={"activate-warranty"}>
                <div className={"activate-warranty-title"}>
                    <Trans>af_homepage.awcard.title</Trans>
                </div>
                <div className={"activate-warranty-desc"}>
                    <Trans>af_homepage.awcard.body</Trans>
                </div>
                <OrderIdInput
                    inputFinish={(order_id) => orderIdInputFinish(order_id)}
                    showWarning={showWarning}
                />
                <Button
                    className={"activate-warranty-submit-btn"}
                    onClick={() => {
                        if (appData.adminConfig.jump_url){
                            // window.location.href = appData.adminConfig.jump_url
                            window.parent.location.href=appData.adminConfig.jump_url
                            return
                        }
                        console.log(appData)
                        if ( appData.failed_act_id) {
                            postRequest('/userapi/upsert_failed_act_user?failed_act_id=' + appData.failed_act_id+'&aid='+appData.adminConfig.user_id, {
                                email: appData.user_email,
                                c_email: appData.adminConfig.email,

                            }).then(res => {
                                if (res.code == -1) {
                                    Toast.show({
                                        content: res.msg,
                                    })
                                } else {
                                    Toast.show({
                                        content: appData.adminConfig.error_msg,
                                    })
                                }
                            }).catch(err => {
                                Toast.show({
                                    content: 'email error',
                                    icon: 'fail'
                                })
                            })
                            // navigate("/activate-warranty-step-1");
                        } else {
                            setShowWarning(true)
                        }
                    }}
                >
                    {t("af_homepage.awcard.button")}
                </Button>
            </div>
        </div>
    );
};

export default Home;
