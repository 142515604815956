import { Image } from "antd-mobile";
import i18n from "i18next";
import React from 'react';
import downArrowIcon from '../../imgs/down-arrow.png';
import franceIcon from '../../imgs/language/france.png';
import japanIcon from '../../imgs/language/japan.png';
import spainIcon from '../../imgs/language/spain.png';
import usIcon from '../../imgs/language/us.png';
import rightArrowIcon from '../../imgs/right-arrow.png';
import './index.scss';

const SwitchLanguage = (props) => {

    const lans = [
        {
            title: 'English',
            language: 'en',
            icon: usIcon
        },
        {
            title: 'French',
            language: 'fr',
            icon: franceIcon
        },
        {
            title: '日本語',
            language: 'ja',
            icon: japanIcon
        },
        {
            title: 'Italian',
            language: 'it',
            icon: spainIcon
        },
        {
            title: 'Spanish',
            language: 'sp',
            icon: spainIcon
        },
        {
            title: 'Deutsch',
            language: 'de',
            icon: spainIcon
        },
    ]

    var currentLanguage =
    {
        title: 'English',
        language: 'en',
        icon: usIcon
    }

    for (const key in lans) {
        if (lans[key].language === i18n.language) {
            currentLanguage = lans[key]
        }
    }

    const Dropdown = () => {
        return (
            <div className={'dropdown'}>
                <ul>
                    {lans.map(item => (
                        <li key={item.language} onClick={() => {
                            props.showAction(false)
                            props.onSwitchLanguage(item.language)
                        }}>
                            {/*<Image className={'d-national-flag'} src={item.icon} />*/}
                            <span className={'d-language'}>{item.title}</span>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    const handleLanguageSwitch = () => {
        // setShowDropDown(!showDropDown)
        props.showAction(!props.show)

    }


    return (
        <div>
            <div className={'switch-language'} onClick={handleLanguageSwitch}>
                {/*<Image className={'national-flag'} src={currentLanguage.icon} />*/}
                <span className={'language'}>{currentLanguage.title}</span>
                <Image className={props.show ? 'down-arrow' : 'right-arrow'}
                    src={props.show ? downArrowIcon : rightArrowIcon} />
            </div>
            {props.show && <Dropdown />}
        </div>

    )
}

export default SwitchLanguage
