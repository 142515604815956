import './index.scss'
import React from 'react'

const NavigateRightOrderView = (props) => {
    return (
        <div className="nav-order-id">ORDER # {props.orderId}</div>
    )
}

export default NavigateRightOrderView
