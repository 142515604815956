import {gql} from "@apollo/client";

export const QUERY_GIFTS = gql`
    query QUERY_GIFTS {
        result:af_gift(where: {is_deleted: {_eq: "0"}}) {
            created_at
            currency_code
            data
            desc
            icon_url
            id
            is_deleted
            name
            price
            products
            quantity
            type
            updated_at
        }
}

`
export const QUERY_GIFTS_WITH_TYPE = gql`
    query QUERY_GIFTS($type: String) {
        result:af_gift(where: {type: {_eq: $type}}) {
            created_at
            currency_code
            data
            desc
            icon_url
            id
            is_deleted
            name
            price
            products
            quantity
            type
            updated_at
        }
}
    `
