import React, {useState, createContext} from 'react';

export const AppDataContext = createContext();

export const AppDataProvider = props => {

    const [appData, setAppData] = useState({});

    return (
        <AppDataContext.Provider value={[appData, setAppData]}>
            {props.children}
        </AppDataContext.Provider>
    );
}
