import { NavBar } from 'antd-mobile';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import AFTabBar from "../../component/AFTabBar";
import NavigateRightOrderView from "../../component/NavigateRightOrderView";
import SwitchLanguage from "../../component/SwitchLanguage";
import { OrderContext } from '../../providers/OrderProvider';
import { SearchContext } from "../../providers/SearchProvider";
import AfterSaleRoutes from "../../routes";
import './index.scss';

export default () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [showTabBar, setShowTabBar] = useState(true);
    const [showLanguageDropdown, setShowLanguageDropdown] = useState(false)
    const [showNavBack, setShowNavBack] = useState(false)
    const [canShowOrder, setCanShowOrder] = useState(false)
    const [showRightView, setShowRightView] = useState(true)
    const [showSearchInput, setShowSearchInput] = useState(false)
    const [orderId] = useContext(OrderContext);
    const [searchText] = useContext(SearchContext);
    const showSwitchLanguageRoutes = ['/', '/after-sale-service', '/watch-video', '/news', '/me', '/cart'];
    const showTabbarsRoutes = [];//['/', '/news', '/me', '/cart'];

    useEffect(() => {
        // setShowLanguageDropdown(false)
        setCanShowOrder(showSwitchLanguageRoutes.indexOf(pathname) === -1)
        console.log(pathname)
        if (pathname === "/choose-gift") {
            setShowRightView(false)
            setShowSearchInput(true)
        } else {
            setShowRightView(true)
            setShowSearchInput(false)
        }
        if (showTabbarsRoutes.indexOf(pathname) !== -1) {
            setShowTabBar(true)
            setShowNavBack(false)
        } else {
            setShowNavBack(true)
            setShowTabBar(false)
        }

    }, [pathname, showSwitchLanguageRoutes, showTabbarsRoutes])

    let { i18n } = useTranslation()
    const changeLanguage = (v) => {
        console.log(v)
        i18n.changeLanguage(v)
    }
    const RightView = () => {
        if (!showRightView) {
            return <></>
        }

        return canShowOrder && orderId ? <NavigateRightOrderView orderId={orderId} /> :
            <SwitchLanguage show={showLanguageDropdown}
                showAction={() => setShowLanguageDropdown(!showLanguageDropdown)}
                onSwitchLanguage={changeLanguage} />
    }
    return (
        <div className={'app'}>
            <div className={'top'}>
                <NavBar height={'50px'} backArrow={showNavBack} onBack={() => navigate(-1)} right={<RightView />}>
                    {showSearchInput && <input type="text" className={"search-input"} placeholder={"Projector Bag"} />}
                </NavBar>
            </div>
            <div className={'body'}>
                <AfterSaleRoutes />
            </div>
            <div className={'bottom'} hidden={!showTabBar}>
                <AFTabBar />
            </div>
        </div>
    )
}
