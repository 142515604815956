/*
 * @Date: 2022-03-02 11:24:02
 * @LastEditors: Qingtong
 * @LastEditTime: 2023-08-04 10:48:05
 * @FilePath: /after-sale-1.0/src/utils/i18n/index.js
 */
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { baseURL } from '../../env';
var aid = 1;
try {
    const location = window.location
    console.log('111111', location)
    let params = location.search.split("?")[1];

    let paramMap = {};
    let paramJson = params.split("&");
    for (var param of paramJson) {
        var k_v = param.split("=");
        if (k_v.length === 2) {
            paramMap[k_v[0]] = k_v[1];
        }
    }
    aid = paramMap['aid']
    console.log("aid123:", aid)
}
catch {
    console.log('no aid')
}
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        backend: {
            loadPath: baseURL + 'userapi/get_lang?aid=' + aid,
            parse: function (data, lng) {
                console.log(lng)
                const lang = JSON.parse(data)[lng]
                console.log(lang)
                return lang;
            },
        },
        react: {
            wait: true,
            useSuspense: false
        }
    });
export default i18n;
