import {gql} from "@apollo/client";

export const QUERY_CONFIG = gql`
    query QUERY_CONFIG($name: String = "default") {
        result:marketing_group(where: {name: {_eq: $name}}) {
            config
        }
    }
`

export const INSERT_UI_SCRIPT = gql`
    mutation INSERT_UI_SCRIPT($af_awfinishpage_body:String, $af_awfinishpage_title:String) {
        insert_ui_script(objects: {af_awfinishpage_body: $af_awfinishpage_body, af_awfinishpage_title: $af_awfinishpage_title}) {
            returning {
                af_awfinishpage_body
                af_awfinishpage_title
            }
        }
    }
`

