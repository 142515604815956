import React, {useState, createContext} from 'react';

export const OrderContext = createContext();

export const OrderProvider = props => {
    const [orderId, setOrderId] = useState(false);

    return (
        <OrderContext.Provider value={[orderId, setOrderId]}>
            {props.children}
        </OrderContext.Provider>
    );
}
