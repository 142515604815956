let baseURL;
let gqlURL;

console.log(process.env.NODE_ENV)

switch (process.env.NODE_ENV) {
  case 'development':
    baseURL = 'https://aftersale.qingtong123.com/'
    gqlURL = 'http://221.194.44.200:28080'
    break;

  case 'production':
    baseURL = 'https://aftersale.qingtong123.com/'
    gqlURL = 'https://hasura.qingtong123.com/'
    break;

  default:
    break
}



export {
  baseURL,
  gqlURL

}

